import { Disclosure } from "@headlessui/react";
import whatsappIcon from "../assets/whatsap.png";
import yatraLogo from "../assets/yatra-header-logo.png"


export default function Navbar() {
  return (
    <Disclosure as="nav" className="shadow-lg h-[7vh] sm:h-[10vh] flex w-[100vw]">
      {({ open }) => (
        <>
          <div className="h-full w-full bg-white flex justify-center z-10">
            <div className="text-white w-[90%] h-[100%] flex justify-between place-items-center">
              <div className="h-[70%] sm:h-[80%] flex">
                <img src={yatraLogo} alt="Your Company" />
              </div>
              <div className="h-[50%] sm:h-[55%] flex gap-5 3xl:gap-8 cursor-pointer ">
                <a href="https://blog.yatrafilms.com/community/" target="blank" className="h-full flex">
                  <button class="text-blue-900 font-bold md:py-0.5 px-4 lg:px-5 3xl:px-6 rounded-lg text-xs sm:text-[8px] md:text-[9px] lg:text-xs xl:text-sm 2xl:text-base 3xl:text-xl outline  outline-blue-900 hover:text-white hover:outline-none hover:bg-blue-900 ease-in-out duration-500 select-none">
                  Blog
                </button>
                </a>
                <a href="https://api.whatsapp.com/send?phone=919773235485" target="blank" className="h-full flex">
                  <img src={whatsappIcon} alt="Your Company" />
                </a>
              </div>
            </div>
          </div>

        </>
      )}
    </Disclosure>
  );
}
