import React, { useState } from "react";
import "../animation.css";
import mobileImg from "../../assets/mobileImg.png";
import posterImg from "../../assets/poster-14.jpg";

const PageThree = ({ slide }) => {
  const [screenSize, setScreenSize] = useState(
    window.innerWidth < 640 ? true : false
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth < 640) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
  });

  return (
    <>
      <>
        {screenSize ? (
          <div>
            <div className="relative w-full max-h-[80vh] text-white ">
              <div className="absolute top-[51.5%] left-1/2 h-[49%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
                <img src={mobileImg} alt="" />
              </div>

              <div className="absolute top-[50.5%] left-1/2 h-[43.3%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
                <div className="overflow-hidden rounded-[8%] bg-[#000000cf]">
                  <div className={`rounded-[8%] h-full w-full flex ${slide === 2 ? 'thumbnailMove' : ''}`}>
                    <img src={posterImg} alt="" className="rounded-[8%]" />
                  </div>
                </div>
              </div>

              <div className="grid grid-rows-2 auto-rows-fr  h-[88vh]">
                <div className="relative">
                  <div
                    className={`absolute top-[137%] right-[8%]  text-white h-[70%] w-[80%] ${slide === 2 ? "moveIn" : ""
                      }`}
                  >
                    <div className="relative w-full h-full gap-[6%]">
                      <h3 className="text-white font-bold sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl text-start ">
                        Join Crazy Ho Community:
                      </h3>
                      <p className="text-white text-[10px] mt-4 text-start ">
                        Ready to join hands and create a change? Don't forget to
                        connect with our Crazy Ho WhatsApp community too - let's
                        spark conversations and drive transformation!

                        <p className="text-slate-200 text-[14px] mt-4 text-start font-semibold">

                          Click the link now -
                          <a
                            href="https://chat.whatsapp.com/JBBYQoWjaPs7SqLwVafQZ9"
                            target="blank"
                          >
                            <span>Join Whatsapp group</span>
                          </a>
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <div
                    className={`absolute top-[-73%] left-[29%]  text-blue-950 h-[20%] w-[60%]`}
                  >
                    <div className="relative w-full h-full flex flex-col justify-center place-items-start gap-[6%]">
                      <div className="flex items-center">
                        <span className={`text-5xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl ${slide === 2 ? "visibleTitleText" : ""}`}>
                          02
                        </span>
                        <div className={`h-20 bg-blue-950 w-[0.5px] mx-2 ${slide === 2 ? "growLineUpMob" : ""
                          }`}></div>
                        <span className={`text-lg md:text-xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-semibold ${slide === 2 ? "visibleTitleText" : ""}`}>
                          Join Community
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`absolute bottom-[10%] ${slide === 2 ? "contactMove" : ""
                      }`}
                  >
                    <a
                      href="https://blog.yatrafilms.com/contact-us/"
                      target="blank "
                    >
                      <button class="bg-white text-blue-950 text-center text-[8px] font-bold p-2 rounded-r-full">
                        Contact Us
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative w-full min-h-[88vh] text-white ">
            <div className="absolute top-[50%] left-1/2 h-[80%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
              <img src={mobileImg} alt="" />
            </div>

            <div className={`absolute top-[49%] left-1/2 h-[70%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20 rounded-[8%] bg-[#000000cf]`}>
              <div className="overflow-hidden rounded-[8%]">
                <div className={`rounded-[8%] h-full w-full flex ${slide === 2 ? 'thumbnailMove' : ''}`}>
                  <img src={posterImg} alt="" className="rounded-[8%]" />
                </div>
              </div>

            </div>

            <div className="grid grid-rows-2 auto-rows-fr  h-[90vh]">
              <div className="relative">
                <div
                  className={`absolute bottom-[1%] right-[9%]  text-white h-[70%] w-[30%] ${slide === 2 ? "moveIn" : ""
                    }`}
                >
                  <div className="relative w-full h-full flex flex-col  gap-[6%]">
                    <h3 className="text-[#3f4e72] font-bold sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl 3xl:text-4xl text-start">
                      Join Crazy Ho Community:
                    </h3>
                    <p className="text-[#3f4e72] sm:text-[7px] md:text-[8px] lg:text-[11px] xl:text-[13px] 2xl:text-[17px] 3xl:text-xl text-start font-semibold">
                      Ready to join hands and create a change? Don't forget to
                      connect with our Crazy Ho WhatsApp community too – let's
                      spark conversations and drive transformation!
                      <p className="text-[#3f4e72] sm:text-[8px] md:text-[9px] lg:text-xs xl:text-sm 2xl:text-lg 3xl:text-xl mt-[2%]  text-start font-semibold">
                        {" "}
                        Calling all change-makers and dreamers!
                      </p>
                      <p className="text-[#3f4e72] sm:text-[8px] md:text-[9px] lg:text-xs xl:text-sm 2xl:text-lg 3xl:text-xl text-start font-semibold">
                        Click the link now -{" "}
                        <a
                          href="https://chat.whatsapp.com/JBBYQoWjaPs7SqLwVafQZ9"
                          target="blank"
                        >
                          Join Whatsapp group
                        </a>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div
                  className={`absolute top-[10%] left-[17%]  text-white h-[50%] w-[26%] `}
                >
                  <div className="relative w-full h-full flex flex-col justify-center place-items-start gap-[6%]">
                    <div
                      className={`sm:text-2xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl ${slide === 2 ? "visibleTitleText" : ""
                        }`}
                    >
                      02
                    </div>
                    <div
                      className={`h-[1%] bg-white w-full ${slide === 2 ? "growText" : ""
                        }`}
                    ></div>
                    <div
                      className={`sm:text-lg md:text-xl lg:text-3xl xl:text-4xl 2xl:text-[42px] 3xl:text-5xl font-semibold ${slide === 2 ? "visibleTitleText" : ""
                        }`}
                    >
                      Join Community
                    </div>
                  </div>
                </div>

                <div
                  className={`absolute bottom-[4%] z-50 ${slide === 2 ? "contactMove" : ""
                    }`}
                >
                  <a href="https://blog.yatrafilms.com/contact-us/" target="blank">
                    <button class="bg-white text-blue-950 text-center sm:text-[8px] md:text-[9px] lg:text-xs xl:text-sm 2xl:text-lg 3xl:text-xl font-bold p-1 md:p-2 xl:p-3 3xl:p-4 rounded-r-full">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default PageThree;
