import React, { useState } from "react";
import YouTube from "react-youtube";
import "../animation.css";
import mobileImg from "../../assets/mobileImg.png";
import leftarrow from "../../assets/left-arrow.png";
import rightarrow from "../../assets/right-arrow.png";
import youtube from "../../assets/youtube-blue.png";
import playButton from "../../assets/play-button.png";
import awardImg from "../../assets/Award-Icons.png";
import awardImgSecond from "../../assets/Award-Icons-2.png";
// import linktree from "../../assets/linkTree-dark.png";
import insta from "../../assets/insta-dark.png";
import posterImg12 from "../../assets/poster-12.jpg";
import posterImg13 from "../../assets/poster-13.jpg";

function PageSix({ slide }) {
  const [screenSize, setScreenSize] = useState(
    window.innerWidth < 640 ? true : false
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth < 640) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
  });

  const [currentPoster, setCurrentPoster] = useState(0);

  // const [previousPoster, setPreviousPoster] = useState(0);

  const [clickDirection, setClickDirection] = useState(true);

  const [showVideo, setShowVideo] = useState(false);

  const [player, setPlayer] = useState(null);

  let awardImages = [awardImgSecond, awardImg]

  let posterImg = [posterImg12, posterImg13];

  const videoId = ["eDvaUHskySc", "c618Wc5Bh5Y"];
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      rel: 0,
    },
  };

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const handlePauseClick = () => {
    setShowVideo(false);
    if (player) {
      player.pauseVideo();
    }
  };

  const handleCurrentPoster = (changeType) => {
    if (changeType === "increase") {
      setClickDirection(true);
      setCurrentPoster((prevPoster) => {
        // setPreviousPoster(prevPoster);
        if (prevPoster + 1 >= posterImg.length) {
          return 0;
        }
        return prevPoster + 1;
      });
    }

    if (changeType === "decrease") {
      setClickDirection(false);
      setCurrentPoster((prevPoster) => {
        // setPreviousPoster(prevPoster);
        if (prevPoster - 1 < 0) {
          return posterImg.length - 1;
        }
        return prevPoster - 1;
      });
    }
  };

  const findClasses = (slide) => {
    if (slide === 0 && clickDirection) {
      return "thumbnailMove";
    }

    if (slide === 0 && !clickDirection) {
      return "thumbnailMoveBack";
    }

    if (slide === 1 && clickDirection) {
      return "thumbnailMove";
    }
    if (slide === 1 && !clickDirection) {
      return "thumbnailMoveBack";
    }
  };

  return (
    <>
      {screenSize ? (
        <div className="relative w-full max-h-[80vh] text-white">
          <div className="absolute top-[51.5%] left-1/2 h-[49%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <img src={mobileImg} alt="" />
          </div>

          <div className="absolute top-[50.9%] left-1/2 h-[43.3%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            
            <div className="overflow-hidden rounded-[8%] bg-[#000000cf]">
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 0 ? '' : 'hidden'} ${(currentPoster === 0 && slide === 5) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[0]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 1 ? '' : 'hidden'} ${(currentPoster === 1) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[1]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
            </div>
          </div>
          <div
            className="absolute top-[49.7%] left-[50.3%] aspect-video min-w-[90%] max-w-[45.6%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-50"
            style={{ display: showVideo ? "block" : "none" }}
          >
            <div className="w-[100%] flex justify-end">
              <button
                className="bg-red-600 w-[8%] font-bold rounded-[15%] sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl"
                onClick={handlePauseClick}
              >
                X
              </button>
            </div>
            <YouTube
              className="h-full w-full"
              videoId={videoId[currentPoster]}
              opts={opts}
              onReady={onReady}
            />
          </div>

          {!showVideo && (
            <div
              class={`absolute w-[7%] flex flex-row justify-center  bg-white rounded-full top-[51%] left-[46%] z-40`}
            >
              <button
                class="hover:scale-105 ease-in-out duration-300 cursor-pointer"
                onClick={() => setShowVideo(!showVideo)}
              >
                <img
                  src={playButton}
                  className="animate-wiggle w-[100%]"
                  alt=""
                />
              </button>
            </div>
          )}

          <div
            className={`grid grid-rows-2 auto-rows-fr  h-[88vh] ${showVideo ? "blur-sm" : ""
              }`}
          >
            <div
              className={`absolute bottom-[1%] ${slide === 5 ? "contactMove" : ""
                }`}
            >
              <a href="https://blog.yatrafilms.com/contact-us/" target="blank ">
                <button class="bg-blue-950 text-white text-center text-[8px]  font-bold p-2 rounded-r-full">
                  Contact Us
                </button>
              </a>
            </div>

            <div
              class={`absolute h-[4%] w-[60.5%] flex flex-row  justify-between top-[68%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-40 ${slide === 3 ? "visibleImg" : ""
                }`}
            >
              <button
                className="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("decrease")}
              >
                <img
                  src={leftarrow}
                  className="animate-wiggle h-[100%] "
                  alt=""
                />
              </button>
              <button
                class="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("increase")}
              >
                <img
                  src={rightarrow}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>

            <div
              class={`absolute h-[4%] w-[30%] flex flex-row justify-between top-[94%] left-[35%] z-40 ${slide === 5 ? "moveUp" : ""
                }`}
            >
              <a
                href="https://www.youtube.com/theconsciousplanet"
                target="blank"
              >
                <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                  <img
                    src={youtube}
                    className="animate-wiggle h-[100%]"
                    alt=""
                  />
                </button>
              </a>

              <a
                href="https://www.instagram.com/the.conscious_travel"
                target="blank"
              >
                <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                  <img src={insta} className="animate-wiggle h-[100%]" alt="" />
                </button>
              </a>
            </div>

            <div className=" relative">
              <div
                className={`absolute top-[118%] left-[25%]  text-blue-950 h-[50%] w-[30%]`}
              >
                <div className="relative w-full h-full flex flex-col justify-center place-items-start gap-[6%]">
                  <div className="flex items-center">
                    <span className={`text-5xl ${slide === 5 ? "visibleTitleText" : ""}`}>05</span>
                    <div className={`h-20 bg-blue-950 w-[0.5px] mx-2 ${slide === 5 ? "growLineMob" : ""
                      }`}></div>
                    <span className={`text-lg  font-semibold ${slide === 5 ? "visibleTitleText" : ""}`}>
                      Our Achivements
                    </span>
                  </div>
                </div>
              </div>

              <div
                class={`absolute w-[60%] flex top-[10%]  right-[18%] z-40 cursor-default ${slide === 5 ? "visibleImg" : ""
                  }`}
              >
                <button className="">
                  <img
                    src={awardImages[currentPoster]}
                    className="cursor-default w-[100%] "
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative w-full min-h-[88vh] text-white ">
          <div className="absolute top-[50%] left-1/2 h-[80%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <img src={mobileImg} alt="" />
          </div>
          <div className="absolute top-[49%] left-1/2 h-[70%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <div className="overflow-hidden rounded-[8%] bg-[#000000cf]">
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 0 ? '' : 'hidden'} ${(currentPoster === 0 && slide === 5) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[0]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 1 ? '' : 'hidden'} ${(currentPoster === 1) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[1]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
            </div>

            <div
              class={`absolute h-[7%] w-[140%] flex flex-row justify-between top-[92%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-40 ${slide === 5 ? "visibleImg" : ""
                }`}
            >
              <button
                className="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("decrease")}
              >
                <img
                  src={leftarrow}
                  className="animate-wiggle h-[100%] "
                  alt=""
                />
              </button>
              <button
                class="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("increase")}
              >
                <img
                  src={rightarrow}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div
            className="absolute top-[49.7%] left-[50.3%] aspect-video min-w-[40.5%] max-w-[45.6%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-50"
            style={{ display: showVideo ? "block" : "none" }}
          >
            <div className="w-[100%] flex justify-end">
              <button
                className="bg-red-600 w-[8%] font-bold rounded-[15%] sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl"
                onClick={handlePauseClick}
              >
                X
              </button>
            </div>
            <YouTube
              className="h-full w-full"
              videoId={videoId[currentPoster]}
              opts={opts}
              onReady={onReady}
            />
          </div>

          {!showVideo && (
            <div
              class={`absolute h-[8%] w-[8%] flex flex-row justify-center top-[54.5%] left-[46%] z-40`}
            >
              <button
                class="bg-white rounded-full hover:scale-105 ease-in-out duration-300 cursor-pointer"
                onClick={() => setShowVideo(!showVideo)}
              >
                <img
                  src={playButton}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>
          )}

          <div
            className={`grid grid-rows-2 auto-rows-fr  h-[90vh] ${showVideo ? "blur-sm" : ""
              }`}
          >
            {" "}
            <div
              className={`absolute bottom-[4%] z-50 ${slide === 5 ? "contactMove" : ""
                }`}
            >
              <a href="https://blog.yatrafilms.com/contact-us/" target="blank">
                <button class="bg-blue-950 text-white text-center sm:text-[8px] md:text-[9px] lg:text-xs xl:text-sm 2xl:text-lg 3xl:text-xl font-bold p-1 md:p-2 xl:p-3 3xl:p-4 rounded-r-full">
                  Contact Us
                </button>
              </a>
            </div>
            
            <div
              class={`absolute h-[4%] w-[12%] flex flex-row justify-around top-[86.5%] left-[44%] z-40 ${slide === 5 ? "moveUp" : ""
                }`}
            >
              <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                <a
                  href="https://www.youtube.com/theconsciousplanet"
                  target="blank"
                >
                  <img
                    src={youtube}
                    className="animate-wiggle h-[100%] tooltip tooltip-bottom"
                    title="Youtube Link"
                    alt=""
                  />
                </a>
              </button>
              <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                <a
                  href="https://www.instagram.com/the.conscious_travel"
                  target="blank"
                >
                  <img src={insta} className="animate-wiggle h-[100%] tooltip tooltip-bottom"
                    title="Instagram Link"
                    alt="" />
                </a>
              </button>
            </div>
            <div className=" relative">
              <div
                className={`absolute top-[25%] left-[13%]  text-white h-[50%] w-[30%] `}
              >
                <div className="relative w-full h-full flex flex-col justify-center place-items-start gap-[6%]">
                  <div
                    className={`sm:text-2xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl ${slide === 5 ? "visibleTitleText" : ""
                      }`}
                  >
                    05
                  </div>
                  <div
                    className={`h-[1%] bg-white w-full ${slide === 5 ? "growText" : ""
                      }`}
                  ></div>
                  <div
                    className={`sm:text-lg md:text-xl lg:text-3xl xl:text-4xl 2xl:text-[42px] 3xl:text-5xl font-semibold ${slide === 5 ? "visibleTitleText" : ""
                      }`}
                  >
                    Our Achivements
                  </div>
                </div>
              </div>

              <div
                class={`absolute w-[33%] flex bottom-[5%] right-[6%] z-40 cursor-default ${slide === 5 ? "visibleImg" : ""
                  }`}
              >
                <button className="">
                  <img
                    src={awardImages[currentPoster]}
                    className="cursor-default h-[100%] "
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PageSix;
