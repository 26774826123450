import React, { useState } from "react";
import "../animation.css";
import linktree from "../../assets/linkTree-dark.png";
import youtube from "../../assets/youtube-blue.png";
import insta from "../../assets/insta-dark.png";
// import contact from "../../assets/contactSlideImg.png";
// import contactMob from "../../assets/contact-Mob2.png";
import contactMob from "../../assets/contact-us-mob.png";
import letsConnectImg from "../../assets/connect-Img.png";

import centerContactImg from "../../assets/contact-us.png"

const PageSeven = ({ slide }) => {
  const [screenSize, setScreenSize] = useState(
    window.innerWidth < 640 ? true : false
  );
  window.addEventListener("resize", () => {
    if (window.innerWidth < 640) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
  });

  return (
    <>
      {screenSize ? (
        // <div className="relative w-full min-h-[80vh] text-white ">
        //   <div className=" absolute w-[70%] aspect-auto left-[50%] top-[35%] -translate-x-1/2 -translate-y-1/2">
        //     <div className="relative h-[50%] w-full flex justify-center">
        //       <img src={contactMob} alt="" />
        //     </div>
        //   </div>
        //   <div
        //     class={`absolute h-[6%] w-[20%] flex flex-row justify-center top-[19%] left-[40%] z-40`}
        //   >
        //     <button class="hover:scale-105 ease-in-out duration-300 cursor-pointer">
        //       <a
        //         href="https://calendly.com/connect-yatrafilms/30-minute-one-on-one"
        //         target="blank"
        //       >
        //         <button class="bg-blue-950 text-white text-center text-[8px]  font-bold p-2 rounded-full ">
        //           Let's Connect !
        //         </button>
        //       </a>
        //     </button>
        //   </div>

        //   <div className="absolute w-[80%] h-[20%] top-[77%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        //     <div className="relative w-full h-full flex flex-col text-blue-950">
        //       <div className="sm:text-xs md:text-sm lg:text-xl xl:text-2xl 2xl:text-3xl text-center font-bold">
        //         Yatra Films
        //       </div>
        //       <div className="sm:text-[7px] md:text-[8px] lg:text-xs xl:text-base 2xl:text-xl  text-center">
        //         Yatra Films 2nd oor, V1 Building Mehra compound, Andheri Kurla
        //         Road,
        //         <br />
        //         Sakinaka, Andheri East, Mumbai, Maharashtra 400072
        //       </div>

        //       <div
        //         class={`flex flex-row w-[80%] h-[21%] ml-[10%] mt-[25%] justify-between z-40 ${slide === 6 ? "moveUp" : ""
        //           }`}
        //       >
        //         <a href="https://www.instagram.com/yatrafilms/" target="blank">
        //           <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
        //             <img src={insta} className="h-[100%]" alt="" />
        //           </button>
        //         </a>

        //         <a
        //           href="https://www.youtube.com/@yatrafilmsofficial"
        //           target="blank"
        //         >
        //           <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
        //             <img src={youtube} className="h-[100%]" alt="" />
        //           </button>
        //         </a>

        //         <a href="https://linktr.ee/yatrafilms" target="blank">
        //           <button className="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
        //             <img src={linktree} className="h-[100%] " alt="" />
        //           </button>
        //         </a>
        //         <a
        //           href="https://www.youtube.com/theconsciousplanet"
        //           target="blank"
        //         >
        //           <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
        //             <img src={youtube} className="h-[100%]" alt="" />
        //           </button>
        //         </a>

        //         <a
        //           href="https://www.instagram.com/the.conscious_travel"
        //           target="blank"
        //         >
        //           <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
        //             <img src={insta} className="h-[100%]" alt="" />
        //           </button>
        //         </a>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div class="mx-auto">

          <div class="flex justify-center ">
            <div className="relative">
              <img src={contactMob} className="w-full max-h-[93vh]" alt="" />

              <div
                class={`absolute w-[25%] flex flex-row justify-center top-[28%] left-[38%] z-40 ${slide === 6 ? "visibleImg" : ""
                  }`}
              >
                <button class="hover:scale-105 ease-in-out duration-300 cursor-pointer">
                  <a
                    href="https://calendly.com/connect-yatrafilms/30-minute-one-on-one"
                    target="blank"
                  >
                    <img
                      src={letsConnectImg}
                      className="animate-wiggle w-[100%]"
                      alt=""
                    />
                  </a>
                </button>
              </div>

              <div className="absolute w-[100%] h-[20%] top-[72%] left-[50%] -translate-x-1/2 -translate-y-1/2">
                <div className="relative w-full h-full flex flex-col text-blue-950">
                  <div className="text-lg sm:text-xs md:text-sm lg:text-xl xl:text-2xl 2xl:text-3xl text-center font-bold">
                    Yatra Films
                  </div>
                  <div className="text-base sm:text-[7px] md:text-[8px] lg:text-xs xl:text-base 2xl:text-xl  text-center">
                    Yatra Films 2nd oor, V1 Building Mehra compound, Andheri Kurla
                    Road,
                    <br />
                    Sakinaka, Andheri East, Mumbai, Maharashtra 400072
                  </div>

                  <div
                    class={`flex flex-row w-[80%] h-[20%] ml-[10%] mt-[3%] justify-between z-40 ${slide === 6 ? "moveUp" : ""
                      }`}
                  >
                    <a href="https://www.instagram.com/yatrafilms/" target="blank">
                      <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                        <img src={insta} className="h-[100%]" alt="" />
                      </button>
                    </a>

                    <a
                      href="https://www.youtube.com/@yatrafilmsofficial"
                      target="blank"
                    >
                      <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                        <img src={youtube} className="h-[100%]" alt="" />
                      </button>
                    </a>

                    <a href="https://linktr.ee/yatrafilms" target="blank">
                      <button className="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                        <img src={linktree} className="h-[100%] " alt="" />
                      </button>
                    </a>
                    <a
                      href="https://www.youtube.com/theconsciousplanet"
                      target="blank"
                    >
                      <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                        <img src={youtube} className="h-[100%]" alt="" />
                      </button>
                    </a>

                    <a
                      href="https://www.instagram.com/the.conscious_travel"
                      target="blank"
                    >
                      <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                        <img src={insta} className="h-[100%]" alt="" />
                      </button>
                    </a>
                  </div>
                </div>
              </div>

            </div >

          </div >
        </div >
      ) : (
        <div class="mx-auto ">

          <div class="flex justify-center ">
            <div className="relative">
              <img src={centerContactImg} className="w-full max-h-[90vh]" alt="" />

              <div
                class={`absolute w-[10%] flex flex-row justify-center top-[37%] left-[45%] z-40 ${slide === 6 ? "visibleImg" : ""
                  }`}
              >
                <button class="hover:scale-105 ease-in-out duration-300 cursor-pointer">
                  <a
                    href="https://calendly.com/connect-yatrafilms/30-minute-one-on-one"
                    target="blank"
                  >
                    <img
                      src={letsConnectImg}
                      className="animate-wiggle w-[100%]"
                      alt=""
                    />
                  </a>
                </button>
              </div>

              <div className="absolute w-[45%] h-[22%] top-[82%] left-[50%] -translate-x-1/2 -translate-y-1/2">
                <div className="relative w-full h-full flex flex-col gap-[6%] text-blue-950">
                  <div className="sm:text-xs md:text-xs lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-center font-bold">
                    Yatra Films
                  </div>
                  <div className="sm:text-[7px] md:text-[8px] lg:text-[11px] xl:text-sm 2xl:text-lg 3xl:text-xl  text-center">
                    Yatra Films 2nd oor, V1 Building Mehra compound, Andheri Kurla
                    Road,
                    <br />
                    Sakinaka, Andheri East, Mumbai, Maharashtra 400072
                  </div>

                  <div
                    class={`flex flex-row w-[50%] h-[20%] ml-[25%] mt-[2%] justify-between z-40 ${slide === 6 ? "moveUp" : ""
                      }`}
                  >
                    <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                      <a
                        href="https://www.instagram.com/yatrafilms/"
                        target="blank"
                      >
                        <img src={insta} className="h-[100%]" alt="" />
                      </a>
                    </button>
                    <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                      <a
                        href="https://www.youtube.com/@yatrafilmsofficial"
                        target="blank"
                      >
                        <img src={youtube} className="h-[100%]" alt="" />
                      </a>
                    </button>
                    <button className="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                      <a href="https://linktr.ee/yatrafilms" target="blank">
                        <img src={linktree} className="h-[100%] " alt="" />
                      </a>
                    </button>
                    <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                      <a
                        href="https://www.youtube.com/theconsciousplanet"
                        target="blank"
                      >
                        <img src={youtube} className="h-[100%]" alt="" />
                      </a>
                    </button>
                    <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                      <a
                        href="https://www.instagram.com/the.conscious_travel"
                        target="blank"
                      >
                        <img src={insta} className="h-[100%]" alt="" />
                      </a>
                    </button>
                  </div>
                </div >
              </div>


            </div >

          </div >
        </div >

      )}
    </>
  );
};

export default PageSeven;
