import React, { useState } from "react";
import Navbar from "./navbar";
import secondaryBGImg from "../assets/white-bg.png";
import mainBgImg from "../assets/background.png";
import mainBgImgMob from "../assets/backgroundMob.png";
// import mainBgImg from "../assets/mainBackground.png";
import PageOne from "./Pages/PageOne";
import PageThree from "./Pages/PageThree";
import PageTwo from "./Pages/PageTwo";
import PageFour from "./Pages/PageFour";
import PageFive from "./Pages/PageFive";
import PageSeven from "./Pages/PageSeven";
import PageSix from "./Pages/PageSix";
import "./style.css";
import "./animation.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Mousewheel, Pagination, Keyboard } from "swiper/modules";

const Main = () => {

  // const isMobile = window.innerWidth <= 640;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(0);


  let slideValue;

  const [screenSize, setScreenSize] = useState(
    window.innerWidth < 640 ? true : false
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth < 640) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
  });

  const background2 = {
    backgroundImage: `url(${!screenSize ? mainBgImg : mainBgImgMob})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // backgroundPositon: "center",
    // backgroundPosition: "fixed",
    width: "100vw",
    height: "100vh",
  };

  const background = {
    backgroundImage: `url(${secondaryBGImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  };

  const handleSlideChange = (swiper) => {
    setCurrentSlide((prev) => {
      setPrevSlide(prev);
      slideValue = prev;
      return swiper.realIndex;
    });

  };

  const findClasses = (slide) => {
    if (slide === 1 && slide < prevSlide) {
      return "rotaterForward";
    }

    if (slide === 1 && slide > prevSlide) {
      return "rotaterBack";
    }

    if (slide === 2 && slide < prevSlide) {
      return "rotaterForward";
    }

    if (slide === 2 && slide > prevSlide) {
      return "rotaterBack";
    }

    if (slide === 3 && slide < prevSlide) {
      return "rotaterForward";
    }

    if (slide === 3 && slide > prevSlide) {
      return "rotaterBack";
    }

    if (slide === 4 && slide < prevSlide) {
      return "rotaterForward";
    }

    if (slide === 4 && slide > prevSlide) {
      return "rotaterBack";
    }

    if (slide === 5 && slide < prevSlide) {
      return "rotaterForward";
    }

    if (slide === 5 && slide > prevSlide) {
      return "rotaterBack";
    }
  };

  return (
    <>
      <div className="h-[100vh] w-[100vw]">
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          keyboard={{
            enabled: true, // Enable keyboard navigation
          }}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
          modules={[Mousewheel, Pagination, Keyboard]}
          className="mySwiper"
          // initialSlide={currentSlide}
        >
          <div className="">
            <div className="mx-auto">
              <div className=" flex flex-col justify-center ">
                <SwiperSlide style={background}>
                  <Navbar />
                  <PageOne />
                </SwiperSlide>

                <SwiperSlide style={background2} >
                  {currentSlide === 1 && (
                    <div className="absolute top-0 -mt-[65vh] sm:-mt-[58.5vh] h-[230vh] -ml-[100vw] w-[300vw] z-0">
                      <div
                        className={`relative grid grid-rows-2 auto-rows-fr h-[230vh] w-[300vw] ${currentSlide === 1 ? findClasses(currentSlide) : ""
                          }`}
                      >
                        <div
                          className={`${currentSlide === 1
                            ? "bg-blue-950"
                            : "bg-transparent"
                            }`}
                        >
                          {""}
                        </div>
                        <div className="bg-transparent"></div>
                      </div>
                    </div>
                  )}
                  <Navbar />
                  <PageTwo slide={currentSlide} />
                </SwiperSlide>

                <SwiperSlide style={background2}>
                  {currentSlide === 2 && (
                    <div className="absolute top-0 -mt-[65vh] sm:-mt-[58.5vh] h-[230vh] -ml-[100vw] w-[300vw] z-0">
                      <div
                        className={`relative grid grid-rows-2 auto-rows-fr h-[230vh] w-[300vw] ${currentSlide === 2 ? findClasses(currentSlide) : ""
                          }`}
                      >
                        <div className="bg-transparent"></div>
                        <div
                          className={`${currentSlide === 2
                            ? "bg-blue-950"
                            : "bg-transparent"
                            } relative`}
                        >
                          {""}
                        </div>
                      </div>
                    </div>
                  )}
                  <Navbar />
                  <PageThree slide={currentSlide} />
                </SwiperSlide>

                <SwiperSlide style={background2}>
                  {currentSlide === 3 && (
                    <div className="absolute top-0 -mt-[65vh] sm:-mt-[58.5vh] h-[230vh] -ml-[100vw] w-[300vw] z-0">
                      <div
                        className={`relative grid grid-rows-2 auto-rows-fr h-[230vh] w-[300vw] ${currentSlide === 3 ? findClasses(currentSlide) : ""
                          }`}
                      >
                        <div
                          className={`${currentSlide === 3
                            ? "bg-blue-950"
                            : "bg-transparent"
                            } relative`}
                        >
                          {""}
                        </div>
                        <div className="bg-transparent"></div>
                      </div>
                    </div>
                  )}
                  <Navbar />
                  <PageFour slide={currentSlide} />
                </SwiperSlide>

                <SwiperSlide style={background2}>
                  {currentSlide === 4 && (
                    <div className="absolute top-0 -mt-[65vh] sm:-mt-[58.5vh] h-[230vh] -ml-[100vw] w-[300vw] z-0">
                      <div
                        className={`relative grid grid-rows-2 auto-rows-fr h-[230vh] w-[300vw] ${currentSlide === 4 ? findClasses(currentSlide) : ""
                          }`}
                      >
                        <div className="bg-trasparent"></div>
                        <div
                          className={`${currentSlide === 4
                            ? "bg-blue-950"
                            : "bg-transparent"
                            } relative`}
                        >
                          {""}
                        </div>
                      </div>
                    </div>
                  )}
                  <Navbar />
                  <PageFive slide={currentSlide} />
                </SwiperSlide>

                <SwiperSlide style={background2}>
                  {currentSlide === 5 && (
                    <div className="absolute top-0 -mt-[65vh] sm:-mt-[58.5vh] h-[230vh] -ml-[100vw] w-[300vw] z-0">
                      <div
                        className={`relative grid grid-rows-2 auto-rows-fr h-[230vh] w-[300vw] ${currentSlide === 5 ? findClasses(currentSlide) : ""
                          }`}
                      >
                        <div
                          className={`${currentSlide === 5
                            ? "bg-blue-950"
                            : "bg-transparent"
                            } relative`}
                        >
                          {""}
                        </div>
                        <div className="bg-transparent"></div>
                      </div>
                    </div>
                  )}
                  <Navbar />
                  <PageSix slide={currentSlide} />
                </SwiperSlide>

                <SwiperSlide style={background2}>
                  <Navbar />
                  <PageSeven slide={currentSlide} />
                </SwiperSlide>

              </div>
            </div>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default Main;
