import React, { useState } from "react";
import YouTube from "react-youtube";
import "../animation.css";
import mobileImg from "../../assets/mobileImg.png";
import leftarrow from "../../assets/left-arrow.png";
import rightarrow from "../../assets/right-arrow.png";
import linktree from "../../assets/linkTree-dark.png";
import youtube from "../../assets/youtube-blue.png";
import insta from "../../assets/insta-dark.png";
import brandsLogo from "../../assets/brandsimg.png";
import brandLogoDesk from "../../assets/brands.png";
import posterImg5 from "../../assets/poster-5.jpg";
import posterImg6 from "../../assets/poster-6.jpg";
import posterImg7 from "../../assets/poster-7.jpg";
import posterImg8 from "../../assets/poster-8.jpg";
import posterImg9 from "../../assets/poster-9.jpg";
import posterImg10 from "../../assets/poster-10.jpg";
import playButton from "../../assets/play-button.png";

function PageFour({ slide }) {
  const [screenSize, setScreenSize] = useState(
    window.innerWidth < 640 ? true : false
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth < 640) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
  });

  const [currentPoster, setCurrentPoster] = useState(0);
  const [previousPoster, setPreviousPoster] = useState(0);

  let posterImg = [
    posterImg7,
    posterImg6,
    posterImg5,
    posterImg8,
    posterImg9,
    posterImg10,
  ];

  const [player, setPlayer] = useState(null);

  const [showVideo, setShowVideo] = useState(false);

  const videoId = [
    "hzF5Dl_Zyc4",
    "sP-HC48AcTY",
    "jWfJETUC95Q",
    "iB_8Pa_lDzk",
    "s9vrEshZseQ",
    "bNgKBS9m3hs",
  ]; // Replace with your YouTube video ID
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0, // Set to 1 to autoplay
      rel: 0,
    },
  };

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const handlePauseClick = () => {
    setShowVideo(false);
    if (player) {
      player.pauseVideo();
    }
  };

  const handleCurrentPoster = (changeType) => {
    if (changeType === "increase") {
      setCurrentPoster((prevPoster) => {
        setPreviousPoster(prevPoster);
        if (prevPoster + 1 >= posterImg.length) {
          return 0;
        }
        return prevPoster + 1;
      });
    }

    if (changeType === "decrease") {
      setCurrentPoster((prevPoster) => {
        setPreviousPoster(prevPoster);
        if (prevPoster - 1 < 0) {
          return posterImg.length - 1;
        }
        return prevPoster - 1;
      });
    }
  };

  const findClasses = (slide) => {
    if ((slide === 0 && previousPoster === 0) || (slide === 0 && previousPoster === posterImg.length - 1)) {
      return "thumbnailMove";
    }

    if ((slide === 0 && slide < previousPoster) || (slide === 0 && previousPoster === posterImg.length - 1)) {
      return "thumbnailMoveBack";
    }

    if (slide === 1 && slide > previousPoster) {
      return "thumbnailMove";
    }
    if (slide === 1 && slide < previousPoster) {
      return "thumbnailMoveBack";
    }

    if (slide === 2 && slide > previousPoster) {
      return "thumbnailMove";
    }
    if (slide === 2 && slide < previousPoster) {
      return "thumbnailMoveBack";
    }

    if (slide === 3 && slide > previousPoster) {
      return "thumbnailMove";
    }
    if (slide === 3 && slide < previousPoster) {
      return "thumbnailMoveBack";
    }

    if (slide === 4 && slide > previousPoster) {
      return "thumbnailMove";
    }
    if (slide === 4 && slide < previousPoster) {
      return "thumbnailMoveBack";
    }

    if (slide === 5 && previousPoster === 4) {
      return "thumbnailMove";
    }
    if (slide === 5 && previousPoster === 0) {
      return "thumbnailMoveBack";
    }
  };

  return (
    <>
      {screenSize ? (
        <div className="relative w-full max-h-[80vh] text-white ">
          <div className="absolute top-[51.5%] left-1/2 h-[49%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <img src={mobileImg} alt="" />
          </div>

          <div className="absolute top-[50.9%] left-1/2 h-[43.3%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <div className="overflow-hidden rounded-[8%] bg-[#000000cf]">
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 0 ? '' : 'hidden'} ${(currentPoster === 0 && slide === 3) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[0]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 1 ? '' : 'hidden'} ${(currentPoster === 1) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[1]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 2 ? '' : 'hidden'} ${(currentPoster === 2) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[2]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 3 ? '' : 'hidden'} ${(currentPoster === 3) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[3]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 4 ? '' : 'hidden'} ${(currentPoster === 4) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[4]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 5 ? '' : 'hidden'} ${(currentPoster === 5) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[5]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>

            </div>
          </div>

          <div
            className="absolute top-[49.7%] left-[50.3%] aspect-video min-w-[90%] max-w-[45.6%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-50"
            style={{ display: showVideo ? "block" : "none" }}
          >
            <div className="w-[100%] flex justify-end">
              <button
                className="bg-red-600 w-[8%] font-bold rounded-[15%] sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl"
                onClick={handlePauseClick}
              >
                X
              </button>
            </div>
            <YouTube
              className="h-full w-full"
              videoId={videoId[currentPoster]}
              opts={opts}
              onReady={onReady}
            />
          </div>

          {!showVideo && (
            <div
              class={`absolute w-[7%] flex flex-row justify-center top-[50%]  bg-white rounded-full left-[46%] z-40`}
            >
              <button
                class="hover:scale-105 ease-in-out duration-300 cursor-pointer"
                onClick={() => setShowVideo(!showVideo)}
              >
                <img
                  src={playButton}
                  className="animate-wiggle w-[100%]"
                  alt=""
                />
              </button>
            </div>
          )}

          <div
            className={`grid grid-rows-2 auto-rows-fr h-[88vh] ${showVideo ? "blur-sm" : ""
              }`}
          >
            <div
              className={`absolute top-[98%]  ${slide === 3 ? "contactMove" : ""
                }`}
            >
              <a href="https://blog.yatrafilms.com/contact-us/" target="blank ">
                <button class="bg-blue-950 text-white text-center text-[8px] font-bold p-2 rounded-r-full">
                  Contact Us
                </button>
              </a>
            </div>

            <div
              class={`absolute h-[4%] w-[60.5%] flex flex-row  justify-between top-[65%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-40 ${slide === 3 ? "visibleImg" : ""
                }`}
            >
              <button
                className="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("decrease")}
              >
                <img
                  src={leftarrow}
                  className="animate-wiggle h-[100%] "
                  alt=""
                />
              </button>
              <button
                class="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("increase")}
              >
                <img
                  src={rightarrow}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>

            <div
              class={`absolute h-[4%] w-[38%] flex flex-row justify-between top-[98%] left-[33%] z-40 ${slide === 3 ? "moveUp" : ""
                }`}
            >
              <a href="https://linktr.ee/yatrafilms" target="blank">
                <button className="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                  <img
                    src={linktree}
                    className="animate-wiggle h-[100%] "
                    alt=""
                  />
                </button>
              </a>
              <a
                href="https://www.youtube.com/@yatrafilmsofficial"
                target="blank"
              >
                <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                  <img
                    src={youtube}
                    className="animate-wiggle h-[100%]"
                    alt=""
                  />
                </button>
              </a>

              <a href="https://www.instagram.com/yatrafilms/" target="blank">
                <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                  <img src={insta} className="animate-wiggle h-[100%]" alt="" />
                </button>
              </a>
            </div>
            <div className="relative">
              <div
                className={`absolute top-[130%] left-[22%] text-blue-950 h-[20%] w-[60%]`}
              >
                <div className="relative w-full h-full flex flex-col justify-center place-items-start gap-[6%]">
                  <div className="flex items-center">
                    <span className={`text-5xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl ${slide === 3 ? "visibleTitleText" : ""}`}>
                      03
                    </span>
                    <div className={`h-12 bg-blue-950 w-[0.5px] mx-2 ${slide === 3 ? "growLineMob" : ""
                      }`}></div>
                    <span className={`text-lg  font-semibold ${slide === 3 ? "visibleTitleText" : ""}`}>About Us</span>
                  </div>
                </div>
              </div>

              <div
                class={`absolute w-[80%] flex top-[158%] right-[10%] z-40 cursor-default ${slide === 3 ? "visibleImg" : ""
                  }`}
              >
                <button className="">
                  <img
                    src={brandsLogo}
                    className="cursor-default w-[100%] "
                    alt=""
                  />
                </button>
              </div>

              <div
                class={`absolute w-[80%] flex top-[148%] right-[-4%] z-40 cursor-default ${slide === 3 ? "visibleImg" : ""
                  }`}
              >
                <div className="text-blue-950 x">BRANDS WE WORKED FOR</div>
              </div>
            </div>
            <div
              className={`absolute top-[3%] right-[10%] text-white h-[50%] 
            w-[80%] ${slide === 1 ? "visibleImg" : ""}`}
            >
              <div className="relative w-full h-1/2 flex flex-col justify-center gap-[7%] ">
                <p className=" text-white text-[10px] text-start">
                  <h3 className="text-white font-bold text-start">
                    We are Experts in Visual Storytelling
                  </h3>
                  Yatra Films is the next generation Content Creation to Post
                  Production studio, with End to End solution that blends its
                  creative skill set with your ideas and thoughts to bring the
                  right impact you need to get a captive audience!
                </p>
                <p className=" text-white text-[10px] text-start">
                  <span className="text-white font-bold text-start">
                    Our Strength - {""}
                  </span>
                  Yatra Films curate, fresh-first-cuts which are then fine-tuned
                  with intricate details and extreme finesse within desired
                  timelines. Experienced team with over a decade-long expertise
                  of seasoned skills and fresh blood to create unforgettable
                  historical moments.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative w-full min-h-[88vh] text-white ">
          <div className="absolute top-[50%] left-1/2 h-[80%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <img src={mobileImg} alt="" />
          </div>
          <div className="absolute top-[49%] left-1/2 h-[70%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <div className="overflow-hidden rounded-[8%] bg-[#000000cf]">
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 0 ? '' : 'hidden'} ${(currentPoster === 0 && slide === 3) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[0]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 1 ? '' : 'hidden'} ${(currentPoster === 1) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[1]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 2 ? '' : 'hidden'} ${(currentPoster === 2) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[2]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 3 ? '' : 'hidden'} ${(currentPoster === 3) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[3]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 4 ? '' : 'hidden'} ${(currentPoster === 4) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[4]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 5 ? '' : 'hidden'} ${(currentPoster === 5) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[5]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>

            </div>


            <div
              class={`absolute h-[7%] w-[140%] flex flex-row justify-between top-[92%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-40 ${slide === 3 ? "visibleImg" : ""
                }`}
            >
              <button
                className="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("decrease")}
              >
                <img
                  src={leftarrow}
                  className="animate-wiggle h-[100%] "
                  alt=""
                />
              </button>
              <button
                class="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("increase")}
              >
                <img
                  src={rightarrow}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div
            className="absolute top-[49.7%] left-[50.3%] aspect-video min-w-[40.5%] max-w-[45.6%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-50"
            style={{ display: showVideo ? "block" : "none" }}
          >
            <div className="w-[100%] flex justify-end">
              <button
                className="bg-red-600 w-[8%] font-bold rounded-[15%] sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl"
                onClick={handlePauseClick}
              >
                X
              </button>
            </div>
            <YouTube
              className="h-full w-full"
              videoId={videoId[currentPoster]}
              opts={opts}
              onReady={onReady}
            />
          </div>

          {!showVideo && (
            <div
              class={`absolute h-[8%] w-[8%] flex flex-row justify-center top-[45%] left-[46%] z-40`}
            >
              <button
                class="hover:scale-105 ease-in-out duration-300 cursor-pointer"
                onClick={() => setShowVideo(!showVideo)}
              >
                <img
                  src={playButton}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>
          )}

          <div
            className={`grid grid-rows-2 auto-rows-fr h-[90vh] ${showVideo ? "blur-sm" : ""
              }`}
          >
            {" "}
            <div
              className={`absolute bottom-[4%] z-10 ${slide === 3 ? "contactMove" : ""
                }`}
            >
              <a href="https://blog.yatrafilms.com/contact-us/" target="blank">
                <button class="bg-blue-950 text-white text-center sm:text-[8px] md:text-[9px] lg:text-xs xl:text-sm 2xl:text-lg 3xl:text-xl font-bold p-1 md:p-2 xl:p-3 3xl:p-4 rounded-r-full">
                  Contact Us
                </button>
              </a>
            </div>
            
            <div
              class={`absolute h-[4%] w-[12%] flex flex-row justify-between top-[86.5%] left-[44%] z-40 ${slide === 3 ? "moveUp" : ""
                }`}
            >
              <button className="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                <a href="https://linktr.ee/yatrafilms" target="blank">
                  <img
                    src={linktree}
                    className="animate-wiggle h-[100%] tooltip tooltip-bottom"
                    title="Linktree Link"
                    alt=""
                  />
                </a>
              </button>
              <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                <a
                  href="https://www.youtube.com/@yatrafilmsofficial"
                  target="blank"
                >
                  <img
                    src={youtube}
                    className="animate-wiggle h-[100%] tooltip tooltip-bottom"
                    title="Youtube Link"
                    alt=""
                  />
                </a>
              </button>
              <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                <a href="https://www.instagram.com/yatrafilms/" target="blank">
                  <img src={insta} className="animate-wiggle h-[100%] tooltip tooltip-bottom"
                    title="Instagram Link"
                    alt="" />
                </a>
              </button>
            </div>
            <div className="relative">
              <div
                className={`absolute top-[20%] left-[13%] text-white h-[50%] w-[30%] `}
              >
                <div className="relative w-full h-full flex flex-col justify-center place-items-start gap-[6%]">
                  <div
                    className={`sm:text-2xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl ${slide === 3 ? "visibleTitleText" : ""
                      }`}
                  >
                    03
                  </div>
                  <div
                    className={`h-[1%] bg-white w-full ${slide === 3 ? "growText" : ""
                      }`}
                  ></div>
                  <div
                    className={`sm:text-lg md:text-xl lg:text-3xl xl:text-4xl 2xl:text-[42px] 3xl:text-5xl font-semibold ${slide === 3 ? "visibleTitleText" : ""
                      }`}
                  >
                    About Us
                  </div>
                </div>
              </div>

              <div
                class={`absolute w-[23%] flex bottom-[5%] right-[14.5%] z-40 cursor-default ${slide === 3 ? "visibleImg" : ""
                  }`}
              >
                <button className="">
                  <img
                    src={brandLogoDesk}
                    className="cursor-default h-[100%] "
                    alt=""
                  />
                </button>
              </div>
            </div>
            <div className="relative">
              <div
                className={`absolute top-[6%] right-[4.5%] text-white w-[33%] ${slide === 3 ? "moveIn" : ""
                  }`}
              >
                <div className="relative w-full h-full flex flex-col ">
                  <h3 className="text-[#3f4e72] font-bold sm:text-[10px] sm:leading-[1.25rem] lg:text-xs xl:text-base 2xl:text-xl 3xl:text-2xl text-start">
                    We are Experts in Visual Storytelling
                  </h3>
                  <p className=" text-[#3f4e72] sm:text-[6px] md:text-[7px] lg:text-[10px] xl:text-xs 2xl:text-base 3xl:text-lg text-start font-medium ">
                    Yatra Films is the next generation Content Creation to Post
                    Production studio, with End to End solution that blends its
                    creative skill set with your ideas and thoughts to bring the
                    right impact you need to get a captive audience!
                  </p>
                  <h3 className="text-[#3f4e72] font-bold sm:text-[10px] sm:leading-[1.25rem] lg:text-xs xl:text-base 2xl:text-xl 3xl:text-2xl text-start lg:pt-[3%]">
                    OUR STRENGTH
                  </h3>
                  <p className=" text-[#3f4e72] sm:text-[6px] md:text-[7px] lg:text-[10px] xl:text-xs 2xl:text-base 3xl:text-lg text-start font-medium ">
                    Yatra Films curate, fresh-first-cuts which are then
                    fine-tuned with intricate details and extreme finesse within
                    desired timelines. Experienced team with over a decade-long
                    expertise of seasoned skills and fresh blood to create
                    unforgettable historical moments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PageFour;
