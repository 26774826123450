import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import "../animation.css";
import mobileImg from "../../assets/mobileImg.png";
import leftarrow from "../../assets/left-arrow-light.png";
import rightarrow from "../../assets/right-arrow-light.png";
// import linktree from "../../assets/linkTree-light.png";
import youtube from "../../assets/youtube-white.png";
// import insta from "../../assets/insta-white.png";
import posterImg1 from "../../assets/poster-1.jpg";
import posterImg2 from "../../assets/poster-2.jpg";
import posterImg4 from "../../assets/poster-4.jpg";
import playButton from "../../assets/play-button.png";

const PageFive = ({ slide }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [currentPoster, setCurrentPoster] = useState(0);
  const [previousPoster, setPreviousPoster] = useState(0);

  const [player, setPlayer] = useState(null);

  let posterImg = [posterImg1, posterImg4, posterImg2];

  const [showVideo, setShowVideo] = useState(false);

  const videoId = ["eDvaUHskySc", "Z-qpMBhqI9Q", "jwRKghZGkMw"]; // Replace with your YouTube video ID
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0, // Set to 1 to autoplay
      rel: 0,
    },
  };

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const handlePauseClick = () => {
    setShowVideo(false);
    if (player) {
      player.pauseVideo();
    }
  };

  const handleCurrentPoster = (changeType) => {
    if (changeType === "increase") {
      setCurrentPoster((prevPoster) => {
        setPreviousPoster(prevPoster);
        if (prevPoster + 1 >= posterImg.length) {
          return 0;
        }
        return prevPoster + 1;
      });
    }

    if (changeType === "decrease") {
      setCurrentPoster((prevPoster) => {
        setPreviousPoster(prevPoster);
        if (prevPoster - 1 < 0) {
          return posterImg.length - 1;
        }
        return prevPoster - 1;
      });
    }
  };

  const findClasses = (slide) => {

    if ((slide === 0 && previousPoster === 0) || (slide === 0 && previousPoster === posterImg.length - 1)) {
      return "thumbnailMove";
    }

    if ((slide === 0 && slide < previousPoster) || (slide === 0 && previousPoster === posterImg.length - 1)) {
      return "thumbnailMoveBack";
    }

    if (slide === 1 && slide > previousPoster) {
      return "thumbnailMove";
    }
    if (slide === 1 && slide < previousPoster) {
      return "thumbnailMoveBack";
    }

    if (slide === 2 && previousPoster === 1) {
      return "thumbnailMove";
    }
    if (slide === 2 && previousPoster === 0) {
      return "thumbnailMoveBack";
    }
  };

  return (
    <>
      {screenSize ? (
        <div className="relative w-full max-h-[80vh] text-white">
          <div className="absolute top-[51.5%] left-1/2 h-[49%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <img src={mobileImg} alt="" />
          </div>

          <div className="absolute top-[50.9%] left-1/2 h-[43.3%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">

            <div className="overflow-hidden rounded-[8%] bg-[#000000cf]">
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 0 ? '' : 'hidden'} ${(currentPoster === 0 && slide === 4) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[0]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 1 ? '' : 'hidden'} ${(currentPoster === 1) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[1]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 2 ? '' : 'hidden'} ${(currentPoster === 2) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[2]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
            </div>
          </div>

          <div
            className="absolute top-[49.7%] left-[50.3%] aspect-video min-w-[90%] max-w-[45.6%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-50"
            style={{ display: showVideo ? "block" : "none" }}
          >
            <div className="w-[100%] flex justify-end">
              <button
                className="bg-red-600 w-[8%] font-bold rounded-[15%] sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl"
                onClick={handlePauseClick}
              >
                X
              </button>
            </div>
            <YouTube
              className="h-full w-full"
              videoId={videoId[currentPoster]}
              opts={opts}
              onReady={onReady}
            />
          </div>

          {!showVideo && (
            <div
              class={`absolute w-[7%] flex flex-row justify-center top-[50%] bg-white rounded-full left-[46%] z-40`}
            >
              <button
                class="hover:scale-105 ease-in-out duration-300 cursor-pointer"
                onClick={() => setShowVideo(!showVideo)}
              >
                <img
                  src={playButton}
                  className="animate-wiggle w-[100%]"
                  alt=""
                />
              </button>
            </div>
          )}

          <div
            className={`grid grid-rows-2 auto-rows-fr h-[88vh] ${showVideo ? "blur-sm" : ""
              }`}
          >
            <div
              class={`absolute h-[4%] w-[35%] flex flex-row justify-between top-[96%] left-[45%] z-40 ${slide === 4 ? "moveUp" : ""
                }`}
            >
              <a
                href="https://www.youtube.com/theconsciousplanet"
                target="blank"
              >
                <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer h-full flex">
                  <img
                    src={youtube}
                    className="animate-wiggle h-[100%]"
                    alt=""
                  />
                </button>
              </a>
            </div>
            <div
              class={`absolute h-[4%] w-[60.5%] flex flex-row  justify-between top-[68%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-40 ${slide === 3 ? "visibleImg" : ""
                }`}
            >
              <button
                className="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("decrease")}
              >
                <img
                  src={leftarrow}
                  className="animate-wiggle h-[100%] "
                  alt=""
                />
              </button>
              <button
                class="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("increase")}
              >
                <img
                  src={rightarrow}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>

            <div className="relative">
              <div
                className={`absolute top-[135%] right-[10%] text-white h-[40%] w-[80%] ${slide === 4 ? "moveIn" : ""
                  }`}
              >
                <div className="relative w-full h-full flex flex-col justify-center  gap-[6%]">
                  <p className="text-sm sm:text-[7px] md:text-[8px] lg:text-[10px] xl:text-sm 2xl:text-base text-start">
                    <span className="sm:text-xs lg:text-base xl:text-lg 2xl:text-2xl font-semibold">
                      The Conscious Planet
                    </span>{" "}
                    - is a place where you need to pause everything… like
                    everything literally and reflect upon the way we live, the
                    content we consume, the thoughts we develop, the stories we
                    hear, and the person we become.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div
                className={`absolute top-[-100%] left-[25%] text-blue-950 h-[20%] w-[86%] `}
              >
                <div className="relative w-full h-full flex flex-col  place-items-start gap-[6%]">
                  <div className="flex items-start">
                    <span className={`text-5xl ${slide === 4 ? "visibleTitleText" : ""}`}>04</span>
                    <div className={`h-52 bg-blue-950 w-[0.5px] mx-2 ${slide === 4 ? "growLineUpMob" : ""
                      }`}></div>
                    {/* <span className={`text-lg  font-semibold ${slide === 4 ? "visibleTitleText" : ""}`}>
                      The Concious Planet
                    </span> */}
                  </div>
                </div>
              </div>

              <div className={`absolute h-[30%] flex flex-col top-[-98%] right-[5%] gap-[5%] w-[50%] ${slide === 4 ? "visibleTitleText" : ""
                }`}>
                <p className=" text-blue-950 text-[10px] text-start">
                  <span className="text-[12px]  font-semibold">
                    The Conscious Planet
                  </span>{" "}
                  - A Bodhi tree of digital world spreading its branches
                  variously representing itself into seven verticals.
                </p>

                <div className="flex flex-col  text-start text-blue-950">
                  <p className="text-[7px] ">
                    <a
                      href="https://www.instagram.com/the.conscious_travel"
                      target="blank"
                      className="hover:text-[#ff7f50]"
                    >
                      The Conscious Travel
                    </a>
                  </p>

                  <p className="text-[7px] ">
                    <a
                      href="https://www.instagram.com/the.conscious_culture"
                      target="blank"
                      className="hover:text-[#ff7f50]"
                    >
                      The Conscious Culture
                    </a>
                  </p>
                  <p className="text-[7px] ">
                    <a
                      href="https://www.instagram.com/the.conscious_music"
                      target="blank"
                      className="hover:text-[#ff7f50]"
                    >
                      The Conscious Music
                    </a>
                  </p>
                  <p className="text-[7px] ">
                    <a
                      href="https://www.instagram.com/the.conscious_arts"
                      target="blank"
                      className="hover:text-[#ff7f50]"
                    >
                      The Conscious Arts
                    </a>
                  </p>
                  <p className="text-[7px] ">
                    <a
                      href="https://www.instagram.com/the.conscious_living"
                      target="blank"
                      className="hover:text-[#ff7f50]"
                    >
                      The Conscious Living
                    </a>
                  </p>
                  <p className="text-[7px] ">
                    <a
                      href="https://www.instagram.com/the.conscious_technology"
                      target="blank"
                      className="hover:text-[#ff7f50]"
                    >
                      The Conscious Technologies
                    </a>
                  </p>
                  <p className="text-[7px] ">
                    <a
                      href="https://www.instagram.com/the.conscious_stories"
                      target="blank"
                    >
                      The Conscious Stories
                    </a>
                  </p>
                </div>
              </div>
              <div
                className={`absolute bottom-[15%] ${slide === 4 ? "contactMove" : ""
                  }`}
              >
                <a href="https://blog.yatrafilms.com/contact-us/" target="blank ">
                  <button class="bg-white text-blue-950 text-center text-[8px]  font-bold p-2 rounded-r-full">
                    Contact Us
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative w-full min-h-[88vh] text-white ">
          <div className="absolute top-[50%] left-1/2 h-[80%] min-w-[20%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            <img src={mobileImg} alt="" />

          </div>

          <div className="absolute top-[49%] left-1/2 h-[70%] max-w-[40%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-20">
            
            <div className="overflow-hidden rounded-[8%] bg-[#000000cf]">
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 0 ? '' : 'hidden'} ${(currentPoster === 0 && slide === 4) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[0]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 1 ? '' : 'hidden'} ${(currentPoster === 1) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[1]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
              <div className={`rounded-[8%] h-full w-full flex ${currentPoster === 2 ? '' : 'hidden'} ${(currentPoster === 2) ? findClasses(currentPoster) : ''}`}>

                <img
                  src={posterImg[2]}
                  alt=""
                  className="rounded-[8%]"
                />

              </div>
            </div>

            <div
              class={`absolute h-[7%] w-[140%] flex flex-row justify-between top-[92%] left-[50%] z-40 -translate-x-1/2 -translate-y-1/2 ${slide === 4 ? "visibleImg" : ""
                }`}
            >
              <button
                className="hover:scale-110 ease-in-out duration-300 cursor-pointer"
                onClick={() => handleCurrentPoster("decrease")}
              >
                <img
                  src={leftarrow}
                  className="animate-wiggle h-[100%] "
                  alt=""
                />
              </button>
              <button
                class="hover:scale-110 ease-in-out duration-300 cursor-pointer "
                onClick={() => handleCurrentPoster("increase")}
              >
                <img
                  src={rightarrow}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>
          </div>

          <div
            className="absolute top-[49.7%] left-[50.3%] aspect-video min-w-[40.5%] max-w-[45.6%] flex justify-center -translate-x-1/2 -translate-y-1/2 z-50"
            style={{ display: showVideo ? "block" : "none" }}
          >
            <div className="w-[100%] flex justify-end">
              <button
                className="bg-red-600 w-[8%] font-bold rounded-[15%] sm:text-sm lg:text-lg xl:text-xl 2xl:text-3xl"
                onClick={handlePauseClick}
              >
                X
              </button>
            </div>
            <YouTube
              className="h-full w-full"
              videoId={videoId[currentPoster]}
              opts={opts}
              onReady={onReady}
            />
          </div>

          {!showVideo && (
            <div
              class={`absolute h-[8%] w-[8%] flex flex-row justify-center top-[45%] left-[46%] z-40`}
            >
              <button
                class="hover:scale-105 ease-in-out duration-300 cursor-pointer"
                onClick={() => setShowVideo(!showVideo)}
              >
                <img
                  src={playButton}
                  className="animate-wiggle h-[100%]"
                  alt=""
                />
              </button>
            </div>
          )}

          <div
            className={`grid grid-rows-2 auto-rows-fr  h-[90vh] ${showVideo ? "blur-sm" : ""
              }`}
          >
            <div
              className={`absolute bottom-[4%] z-50 ${slide === 4 ? "contactMove" : ""
                }`}
            >
              <a href="https://blog.yatrafilms.com/contact-us/" target="blank">
                <button class="bg-white text-blue-950 text-center sm:text-[8px] md:text-[9px] lg:text-xs xl:text-sm 2xl:text-lg 3xl:text-xl font-bold p-1 md:p-2 xl:p-3 3xl:p-4 rounded-r-full cursor-pointer">
                  Contact Us
                </button>
              </a>
            </div>

            <div
              class={`absolute h-[4%] w-[12%] flex flex-row justify-center top-[86.5%] left-[44%] z-40 ${slide === 4 ? "moveUp" : ""
                }`}
            >
              <button class="hover:scale-110 ease-in-out duration-300 cursor-pointer">
                <a
                  href="https://www.youtube.com/theconsciousplanet"
                  target="blank"
                >
                  <img
                    src={youtube}
                    className="animate-wiggle h-[100%] tooltip tooltip-bottom"
                    title="Youtube Link"
                    alt=""
                  />
                </a>
              </button>
            </div>

            <div className="relative">
              <div
                className={`absolute top-[30%] right-[7%]  text-blue-950 h-[70%] w-[30%] ${slide === 4 ? "moveIn" : ""
                  }`}
              >
                <div className="relative w-full h-full flex flex-col justify-center  gap-[6%]">
                  <p className=" sm:text-[7px] md:text-[8px] lg:text-[10px] xl:text-sm 2xl:text-base 3xl:text-xl text-start">
                    <span className="sm:text-xs lg:text-base xl:text-lg 2xl:text-2xl 3xl:text-3xl font-semibold">
                      The Conscious Planet
                    </span>{" "}
                    - is a place where you need to pause everything… like
                    everything literally and reflect upon the way we live, the
                    content we consume, the thoughts we develop, the stories we
                    hear, and the person we become.
                  </p>
                  <p className="sm:text-[7px] md:text-[8px] lg:text-[10px] xl:text-sm 2xl:text-base 3xl:text-xl text-start  ">
                    The Conscious Planet will be one of its kind platform for
                    creating, producing, and distributing purposeful content
                    which makes us pause and reflect upon ourselves and the
                    knowledge we have about everything around us.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div
                className={`absolute top-[10%] left-[10%]  text-white h-[50%] w-[33%] `}
              >
                <div className="relative w-full h-full flex flex-col justify-center place-items-start gap-[6%]">
                  <div
                    className={`sm:text-2xl md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl 3xl:text-8xl ${slide === 4 ? "visibleTitleText" : ""
                      }`}
                  >
                    04
                  </div>
                  <div
                    className={`h-[1%] bg-white w-full ${slide === 4 ? "growText" : ""
                      }`}
                  ></div>
                  <div
                    className={`sm:text-lg md:text-xl lg:text-3xl xl:text-4xl 2xl:text-[42px] 3xl:text-5xl font-semibold ${slide === 4 ? "visibleTitleText" : ""
                      }`}
                  >
                    The Conscious Planet
                  </div>
                </div>
              </div>

              <div
                className={`absolute top-[6%] right-[3%] text-white  w-[34%] ${slide === 4 ? "visibleImg" : ""
                  } `}
              >
                <div className="relative w-full h-full flex flex-col gap-[7%] ">
                  <p className=" text-white sm:text-[7px] md:text-[8px] lg:text-[10px] xl:text-sm 2xl:text-base 3xl:text-lg text-start">
                    <span className="sm:text-xs lg:text-base xl:text-lg 2xl:text-2xl font-semibold">
                      The Conscious Planet
                    </span>{" "}
                    - A Bodhi tree of digital world spreading its branches
                    variously representing itself into seven verticals.
                  </p>

                  <div className="flex flex-col pt-[3%] text-start">
                    <p className="sm:text-[8px] md:text-[9px] lg:text-xs xl:text-base 2xl:text-xl ">
                      <a
                        href="https://www.instagram.com/the.conscious_travel"
                        target="blank"
                        className="hover:text-[#ff7f50]"
                      >
                        The Conscious Travel
                      </a>
                    </p>
                    <p className="sm:text-[8px] md:text-[9px] lg:text-xs xl:text-base 2xl:text-xl">
                      <a
                        href="https://www.instagram.com/the.conscious_culture"
                        target="blank"
                        className="hover:text-[#ff7f50]"
                      >
                        The Conscious Culture
                      </a>
                    </p>
                    <p className="sm:text-[8px] md:text-[9px] lg:text-xs xl:text-base 2xl:text-xl">
                      <a
                        href="https://www.instagram.com/the.conscious_music"
                        target="blank"
                        className="hover:text-[#ff7f50]"
                      >
                        The Conscious Music
                      </a>
                    </p>
                    <p className="sm:text-[8px] md:text-[9px] lg:text-xs xl:text-base 2xl:text-xl">
                      <a
                        href="https://www.instagram.com/the.conscious_arts"
                        target="blank"
                        className="hover:text-[#ff7f50]"
                      >
                        The Conscious Arts
                      </a>
                    </p>
                    <p className="sm:text-[8px] md:text-[9px] lg:text-xs xl:text-base 2xl:text-xl">
                      <a
                        href="https://www.instagram.com/the.conscious_living"
                        target="blank"
                        className="hover:text-[#ff7f50]"
                      >
                        The Conscious Living
                      </a>
                    </p>
                    <p className="sm:text-[8px] md:text-[9px] lg:text-xs xl:text-base 2xl:text-xl">
                      <a
                        href="https://www.instagram.com/the.conscious_technology"
                        target="blank"
                        className="hover:text-[#ff7f50]"
                      >
                        The Conscious Technologies
                      </a>
                    </p>
                    <p className="sm:text-[8px] md:text-[9px] lg:text-xs xl:text-base 2xl:text-xl">
                      <a
                        href="https://www.instagram.com/the.conscious_stories"
                        target="blank"
                        className="hover:text-[#ff7f50]"
                      >
                        The Conscious Stories
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageFive;
