import React, { useState, } from "react";
import "../animation.css";
import yatraTitleImg from "../../assets/yatra-new.png";
import yatraTitleImgMob from "../../assets/yatra-new-mobile.png";
import tagline from "../../assets/tagline-yatra.png";
import taglineMob from "../../assets/tagline-yatra-mobile.png";
import downArrowImg from "../../assets/down-Arrow.png";
import doodleBg from "../../assets/background.png";
import doodleBgMob from "../../assets/backgroundMob.png";

function PageOne() {

  const [mousePosition, setMousePosition] = useState({X:'50%', Y:'-55%'});
  const [touchPosition, setTouchPosition] = useState({X:'50%', Y:'-50%'});

  const [screenSize, setScreenSize] = useState(
    window.innerWidth < 640 ? true : false
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth < 640) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
  });

  let light = {
    position: 'fixed',
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background: `radial-gradient(circle at ${mousePosition.X} ${mousePosition.Y}, transparent 10%, rgba(255, 255, 255, 1) 15%)`
  }
  
  let mobileLight = {
    position: 'fixed',
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background: `radial-gradient(circle at ${touchPosition.X} ${touchPosition.Y}, transparent 10%, rgba(255, 255, 255, 1) 15%)`
  }

  const spotLight = (e) => {
    let X = e.clientX + 'px';
    let Y = e.clientY + 'px';

    setMousePosition({ X, Y })

  };
  
  const spotLightTouch = (e) => {
    const touch = e.touches[0];
    let X = touch.clientX + 'px';
    let Y = touch.clientY + 'px';

    setTouchPosition({ X, Y })

  };

  return (
    <>
      {screenSize ? (
        <>
          <div class="mx-auto " onTouchMove={spotLightTouch}>

            <div className="absolute top-[7%] left-0 h-[93%] w-full -z-10">
              <div className="relative h-full w-full">
                <img src={doodleBgMob} className=" w-full h-full mx-auto" alt="" />
                <div className="absolute top-0 left-0 w-full h-full">

                </div>
                <div className="" style={mobileLight}> </div>
              </div>

            </div>

            <div class="flex justify-center ">
              <div className="relative ">
                <img src={yatraTitleImgMob} className="w-full max-h-[90vh]" alt="" />

                <div className="absolute top-[0%] left-0">
                  <img src={taglineMob} className="w-full h-full mx-auto" alt="" />
                </div>

                <div className="absolute top-[80%] left-[45%] w-[14%] flex justify-center bounce">
                  <img src={downArrowImg} className="" alt="" />
                </div>

              </div>

            </div>
          </div>
        </>
      ) : (

        <div class="mx-auto " onMouseMove={spotLight}>

          <div className="absolute top-[10%] left-0 h-[90%] w-full -z-10">
            <div className="relative h-full w-full">
              <img src={doodleBg} className=" w-full h-full mx-auto" alt="" />
              <div className="absolute top-0 left-0 w-full h-full">

              </div>
              <div className="" style={light}> </div>
            </div>

          </div>

          <div class="flex justify-center ">
            <div className="relative ">
              <img src={yatraTitleImg} className="w-full max-h-[90vh]" alt="" />

              <div className="absolute top-0 left-0 w-full h-full mx-auto">
                <img src={tagline} className="w-full h-full mx-auto" alt="" />
              </div>

              <div className="absolute top-[80%] left-[48%] w-[4%] flex justify-center bounce">
                <img src={downArrowImg} className="" alt="" />
              </div>

            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default PageOne;